<template>
  <div class="AdminS-box">
    <p class="AdminS-p"><i></i><span>子管理员设置</span></p>
    <div class="AdminS">
      <div class="AdminS-title">
        <el-button @click="drawer = true" icon="el-icon-user" class="AdminS-button-add">
            <span>添加子管理员</span>
        </el-button>
        <el-button class="AdminS-button-del" icon="el-icon-delete">
            <span>批量删除</span>
        </el-button>
      </div>
      <div class="AdminS-drawer">
        <el-drawer
         title="我是标题"
         :visible.sync="drawer"
         :with-header="false"
         size="480px">
            <div class="AdminS-drawer-title">
               <span></span>
             新增/编辑子管理员
            </div>
            <div class="AdminS-drawer-personnel">
               <span>选择人员:</span>
               <el-select v-model="value" placeholder="请选择" size="280px">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            </div>
            <div class="AdminS-drawer-jurisdiction">
               <p>选择权限:</p>
               <ul>
                  <li><el-checkbox></el-checkbox><span>目标</span></li>
                  <li><el-checkbox></el-checkbox><span>点单</span></li>
                  <li><el-checkbox></el-checkbox><span>备餐</span></li>
                  <li><el-checkbox></el-checkbox><span>烹饪</span></li>
                  <li><el-checkbox></el-checkbox><span>库房</span></li>
                  <li><el-checkbox></el-checkbox><span>采购</span></li>
                  <li><el-checkbox></el-checkbox><span>质检</span></li>
                  <li><el-checkbox></el-checkbox><span>人力</span></li>
               </ul>
            </div>
            <div class="admin-alter">
              <a class="admin-confirm" @click="ConfirmSubmit">确定</a>
              <a class="admin-cancel" @click="cancelSubmit">取消</a>
            </div>
         </el-drawer>
        </div>
     <div class="AdminS-table">
        <el-table
         ref="multipleTable"
         :data="tableData"
         tooltip-effect="dark"
         style="width:100%"
         @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            width="100"
            >
            </el-table-column>
            <el-table-column
            label="姓名">
            <template slot-scope="scope">{{ scope.row.date }}</template>
            </el-table-column>
            <el-table-column
            prop="name"
            label="权限">
            </el-table-column>
            <el-table-column
            prop="alter"
            label="操作"
            show-overflow-tooltip>
               <img src="../../../assets/img/xiu.png" alt="" style="width: 23px;height: 24px">
            </el-table-column>
         </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      tableData: [{
        date: '李庆和',
        name: '点单结账，销售'
      },
      {
        date: '王子文',
        name: '库房，采购'
      },
      {
        date: '李玲',
        name: '人力，财务，销售'
      }],
      multipleSelection: [],
      options: [{
        value: '选项1',
        label: '销售部 >'
      }, {
        value: '选项2',
        label: '采购部 >'
      }, {
        value: '选项3',
        label: '人力部 >'
      }, {
        value: '选项4',
        label: '财务部 >'
      }],
      value: ''
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    ConfirmSubmit() {
      this.drawer = false
    },
    cancelSubmit() {
      this.drawer = false
    }
  }
}
</script>

<style scoed lang="scss">
.el-main{
  // 
  background: #F6F6FA;
}

.AdminS-p{
  font-size: 18px;
  color: #999999;
  display: flex;
  margin: 26px 0 30px 0;
  span{
    margin: 4px;
  }
  i{
    background: url('../../../assets/img/din.png') no-repeat;
    width: 23px;
    height: 30px;
    display: block;
    margin-right: 11px;
  }
}
.AdminS{
  width: 100%;
  color: #f6f6f3;
  background: #F2F6FC;
  -moz-box-shadow:0px 0px 10px #e8e8eb; -webkit-box-shadow:0px 0px 10px #e8e8eb; box-shadow:0px 0px 10px #e8e8eb;
  .AdminS-title{
    display: flex;
    width: 100%;
    height: 80px;
    background: linear-gradient(180deg, #FC4353, #FF5E41);
    border-radius: 15px 15px 0px 0px;
    align-items: center;
    box-sizing: border-box;

  }
  .AdminS-drawer{
     .AdminS-drawer-title{
      padding: 27px 0 0 31px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      display: flex;
      justify-content:left;
      align-items: center;
      color: #333333;
      span{
         display: inline-block;
         width: 6px;
         height: 32px;
         background: linear-gradient(180deg, #FC4353, #FF5E41);
         border-radius: 3px;
         margin-right: 10px;
      }
     }
     .AdminS-drawer-personnel{
        padding: 53px 0 0 44px;
        min-height: 280px;
        & > span{
         display: inline-block;
         font-size: 16px;
         font-family: Source Han Sans CN;
         font-weight: 400;
         color: #333333;
        //  margin-bottom: 20px;
         margin-right: 13px;
        }
     }
     .AdminS-drawer-jurisdiction{
        padding: 0 0 0 44px;
        p{
         font-size: 16px;
         font-family: Source Han Sans CN;
         font-weight: 400;
         color: #333333;
         margin-bottom: 20px;
        }
        ul{
           padding: 0 0 0 88px;
           li{
              margin-bottom: 10px;
              display: flex;
              justify-content:left;
              align-items: center;
              span{
               font-size: 18px;
               font-family: Source Han Sans CN;
               font-weight: 400;
               color: #333333;
               display: inline-block;
               margin-left: 20px;
              }
           }
        }
     }

  }
  .AdminS-drawer-button{
     padding:50px 0 0 83px;
     button{
      width: 110px;
      height: 50px;
      outline: none;
      border: none;
      border-radius: 10px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
     }
     .confirm{
      background: linear-gradient(200deg, #FC4353, #FF5E41);
      color: #fff;
      margin-right: 100px;
     }
     .cancel{
      color: #666666;
     }
  }
  .AdminS-table{
     width: 100%;
  }
}
</style>
